import React, { Fragment, useEffect } from "react";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import { useNavigate, useLocation } from "react-router";
import { ArrowBack } from "@mui/icons-material";
import Typography from "@mui/material/Typography";
import InputField2 from "../../common/InputComponents/InputField2";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { FormProvider, useForm } from "react-hook-form";
import ToggleButtons from "../../common/InputComponents/ToggleButtons";
import ClearIcon from "@mui/icons-material/Clear";

import { Box, Button } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import {
    Loading,
    useCreate,
    useGetIdentity,
    useGetList,
    useNotify,
    useRefresh,
    useStore,
} from "react-admin";
import { useParams } from "react-router-dom";
import save_icon from "../../../assets/images/save_icon.svg";
import formValidation from "../../../yupFormValidationRules/formValidator";
import InputSelectField from "../../common/InputComponents/InputSelectField";
import TextInputPhone from "../../DocumentAnalysis/components/inputComponents/TextInputPhone";
import InputCheckBoxField from "../../common/InputComponents/InputCheckBoxField";
import { JSONTree } from "react-json-tree";
import { AgentList } from "../../Ats/NewApplicationModel";
import InputFileUpload from "../../common/InputComponents/InputFileUpload";
import FilePreview2 from "../../common/InputComponents/FilePreview2";
import { CircularProgress } from "@mui/material";
import MFAPopup from "../../manageUsers/MFAPopup";
import AddUserForAdmin from "../../manageUsers/AddUserForAdmin";
import { useSelector } from "react-redux";
const AddOrUpdateUser = () => {
    const navigate = useNavigate();
    const { state } = useLocation();
    const [open, setOpen] = React.useState(false);
    const notify = useNotify();
    const refresh = useRefresh();
    const toggleButton = [
        {
            value: 0,
            label: "Inactive",
            icon: <ClearIcon />,
            borderColor: "#909090",
            backgroundColor: "#EEE",
            boxShadowColor: "#90909033",
            color: "#606060",
        },
        {
            value: 1,
            label: "Active",
            icon: <CheckIcon />,
            borderColor: "#46A96F",
            backgroundColor: "#EBFFF3",
            boxShadowColor: "#46a96f1a",
            color: "#46A96F",
        },
    ];

    const [switchSubscriberObj] = useStore("switchSubscriberName");
    const { id } = useParams();
    const associationTypes = [
        {
            AssociationType: "Property/Office",
            id: 1,
        },
        // {
        //     AssociationType: "Hierarchy",
        //     id: 2,
        // },

    ];
    // get integration-setting-rule details
    const {
        data: getHierarchyLevelDetails,
        isLoading: loadingHierarchyLevelDetails,
    } = useGetList(
        "hierarchy-levels-list",
        {
            filter: {
                subscriberID: switchSubscriberObj?.value,
                hierarchyStatus: "1",
            },
        },
        {
            enabled: switchSubscriberObj?.value ? true : false,
            refetchOnWindowFocus: false,
        }
    );
    const {
        data: getrole,
        isLoading: loadingrole,
    } = useGetList(
        "get-role",
        {
            filter: {
                subscriberid: switchSubscriberObj?.value,
                //id: id,
            },
        },
    );
    console.log(getrole, "getrole")
    const {
        data: getassociation,
        isLoading: loadingassociation,
    } = useGetList(
        "get-association",
        {
            filter: {
                subscriberid: switchSubscriberObj?.value,
                //id: id,
            },
        },
    );
    // const {
    //     data: getagent,
    //     isLoading: loadingagent,
    // } = useGetList(
    //     "get-agent",
    //     {
    //         filter: {
    //             subscriberid: switchSubscriberObj?.value,

    //         },
    //     },
    // );
    const {
        data: getmanager,
        isLoading: loadingmanager,
    } = useGetList(
        "get-manager",
        {
            filter: {
                subscriberid: switchSubscriberObj?.value,

            },
        },
    );
    console.log(getrole, "data");
    // form validation schema
    const validationSchema = yup.lazy((value) =>
        yup.object().shape({
            firstname: formValidation.name,
            lastname: formValidation.name,
            active: formValidation.StringRequiredValidation,
            email: formValidation.email,
            twofactorenabled: formValidation.StringRequiredValidation,
            mobile: formValidation.mobileNo,
            //  otherphone: formValidation.mobileNo,
            usertype: formValidation.validationForDropDown,
            username: yup.string()
                .when("isregister", {
                    is: (value) => {
                        return value == 1;
                    },
                    then: yup
                        .string()
                        .typeError("required")
                        .required("required")
                }),
            associationType: yup
                .object()
                .when("usertype", {
                    is: (usertype) => usertype?.value === "Agent" || usertype?.value === "Manager",
                    then: yup.object().typeError("Required").required("Required!"),
                    otherwise: yup.object().nullable(), // Not required if usertype is not "Agent"
                }),

            managerid: yup
                .object()
                .when("usertype", {
                    is: (usertype) => usertype?.value === "Agent",
                    then: yup.object().typeError("Required").required("Required!"),
                    otherwise: yup.object().nullable(), // Not required if usertype is not "Agent"
                }),
            // agentlist: yup
            // .array()
            // .when("usertype", {
            //   is: (usertype) => usertype?.value === "Manager",
            //   then: yup
            //     .array()
            //     .min(1, "Required!")
            //     .of(
            //       yup.object().shape({
            //         // Assuming each item in the array is an object with specific fields
            //         value: yup.string().required("Value is required!"),
            //         label: yup.string().required("Label is required!"),
            //       })
            //     ),
            //   otherwise: yup.array().nullable(),

            // }),
            // agentlist: formValidation.validationForDropDown,
          
            officeList: yup
                .array()
                .when("associationType", {
                    is: (associationType) => associationType?.label === "Property/Office",
                    then: yup
                        .array()
                        .min(1, "Required!")
                        .of(
                            yup.object().shape({
                                // Assuming each item in the array is an object with specific fields
                                value: yup.string().required("Value is required!"),
                                label: yup.string().required("Label is required!"),
                            })
                        ),
                    otherwise: yup.array().nullable(),
                }),


        })
    );

    // Initial form values
    const defaultFormValues = {
        firstname: "",
        lastname: "",
        active: 1,
        twofactorenabled: 1,
        isregister: false,
        email: "",
        username: "",
        mobile: null,
        otherphone: null,
        usertype: "",
        officeList: [],
        agentlist: [],
        managerid: "",
        hierarchyList:[],
        associationType:"",
    };

    const methods = useForm({
        defaultValues: defaultFormValues,
        resolver: yupResolver(validationSchema),
        mode: "onChange",
    });
 const { data: identity } = useGetIdentity();
  const selectedOfficeIds = methods.watch<any>('officeList')?.map((item: any) => item?.value);
  const userRole = useSelector((state: any) => state?.users?.role) || identity?.userrole;
  let filterData: any = [];
  // const office1= methods?.watch<any>().office?.label;
  if (methods.watch().officeList !== null) {
      let filter = getassociation?.filter((office: any) =>
          selectedOfficeIds?.includes(office.ID)
      );
      filterData = filter;
  }
  console.log(filterData,"filterData");
  // call get agent list data
  const adminurl = filterData?.map((item) => item?.URL);
  // const agenturl = getassociation?.UserURL;
  const { data: agentdata } = useGetList(
      "getagentlist",
      {

          filter: {
              url: adminurl, SubscriberID: filterData?.[0]?.SubscriberID
          },
      },
      { enabled: adminurl && filterData?.[0]?.SubscriberID ? true : false }
  );
    // useEffect(() => {
    //     if (!state?.isAdding) {
    //         let data = getHierarchyLevelDetails && getHierarchyLevelDetails[0];
    //         const getFormData: any = {
    //             LevelCode: data?.LevelCode,
    //             HierarchyName: data?.HierarchyName,
    //             Active: data?.Active,s
    //         };
    //         methods.reset(getFormData);
    //     }
    // }, [getHierarchyLevelDetails && getHierarchyLevelDetails[0], methods]);

    const [create, { isLoading, error }] = useCreate();
    const { data } = JSON.parse(localStorage.getItem("auth") || "");
    // form submit handler
    const onSubmit = (value) => {

        const addData = {
            ...value,
            subscriberid: parseInt(switchSubscriberObj?.value) || data?.subscriberid,
            usertype: value?.usertype?.value,
            officeList: value?.officeList?.map((item) => Number(item.value)),
            agentlist: value?.agentlist?.map((item) => Number(item.value)),
            hierarchyList : value?.hierarchyList?.map((item)=>Number(item?.value)),
            managerid: value?.managerid?.value,
            firstname: value?.firstname?.trim(),
            lastname: value?.lastname?.trim(),
            active: parseInt(value?.active),
            twofactorenabled: parseInt(value?.twofactorenabled),
            isregister: value?.isregister,
            email: value?.email,
            username: value?.username,
            mobile: value?.mobile,
            otherphone: value?.otherphone,


        };

    
        create(
            `add-user`,
            { data: addData },
            {
                onSuccess: (data) => {
                    notify(


                        `User created successfully.`,


                        {
                            type: "success",
                            anchorOrigin: { vertical: "top", horizontal: "right" },
                        }
                    );
                    refresh();
                    methods?.reset();
                    navigate(-1);
                },
                onError: (e: any) => {
                    notify(`Operation fail:${e.message}`, {
                        type: "error",
                        anchorOrigin: { vertical: "top", horizontal: "right" },
                    })
                    if (e.message == 'User Already Exists!') {
                        methods.setValue('isregister', true)
                    }
                },


            }
        );

    };


    // if (loadingHierarchyLevelDetails) return <Loading />
    const isManager = methods.watch<any>("usertype")?.label === "Manager";
    const isAdmin = methods.watch<any>("usertype")?.label === "Admin";
    const isAgent = methods.watch<any>("usertype")?.label === "Agent";
    const associationtype = methods.watch<any>("associationType");
    console.log(associationtype, "dump")
    return (
        <Fragment>
            <div className="home_content">
                <div className="row">
                    <div className="col-12 d-flex">
                        <Breadcrumbs aria-label="breadcrumb">
                            <Typography sx={{ fontWeight: "bold" }}>
                                <button
                                    title="Go Back"
                                    style={{ color: "#2cc0e4" }}
                                    className="btn btn-link"
                                    onClick={() => navigate(-1)}
                                >
                                    <ArrowBack />
                                </button>

                                <span>Add New User</span>

                            </Typography>
                        </Breadcrumbs>
                    </div>
                </div>
                <FormProvider {...methods}>
                    <form onSubmit={methods.handleSubmit(onSubmit)} className="mr-t30">
                      
                        <div className="row">
                            <div className="col-sm-12 col-md-4 col-lg-4 mr-b20">
                                <InputField2
                                    label="First Name"
                                    source={`firstname`}
                                    placeholder="Enter First Name"
                                    labelClass={"lable-title"}
                                    additionalInputClass={"cus-control"}
                                />
                            </div>
                            <div className="col-sm-12 col-md-4 col-lg-4 mr-b20">
                                <InputField2
                                    label="Last Name"
                                    source={`lastname`}
                                    placeholder="Enter Last Name"
                                    labelClass={"lable-title"}
                                    additionalInputClass={"cus-control"}
                                />
                            </div>
                            <div className="col-sm-12 col-md-4 col-lg-4 mr-b20">
                                <ToggleButtons
                                    toggleButton={toggleButton}
                                    label="Status"
                                    labelClass={"lable-title"}
                                    source={`active`}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-12 col-md-4 col-lg-4 mr-b20">
                                <InputField2
                                    label="Email"
                                    source={`email`}
                                    placeholder="Email"
                                    labelClass={"lable-title"}
                                    additionalInputClass={"cus-control"}
                                />
                            </div>

                            <div className="col-sm-12 col-md-4 col-lg-4 mr-t30">

                                <InputCheckBoxField
                                    notRequired={true}
                                    disabled={false}

                                    label="Register using Username"
                                    name={`isregister`}
                                />
                            </div>

                            {methods?.watch()?.isregister && <div className="col-sm-12 col-md-4 col-lg-4 mr-b20">
                                <InputField2
                                    label="User Name"
                                    source={`username`}
                                    placeholder="Enter User Name"
                                    labelClass={"lable-title"}

                                    additionalInputClass={"cus-control"}
                                />
                            </div>}
                        </div>
                        <div className="row">
                            <div className="col-sm-12 col-md-4 col-lg-4 mr-b20">
                                <TextInputPhone
                                    source={`mobile`}
                                    label={`Mobile Number`}
                                    // isCountryCode={`applicant.mobile.${0}.country_code`}
                                    labelClass={"lable-title"}
                                    additionalInputClass={"cus-control"}
                                    widthClass={"w-100"}
                                    requiredClass={{
                                        fontSize: "70%",
                                    }}
                                // onChangeCallback={() => {
                                //   props?.handleAPIDebounced();
                                // }}
                                />
                            </div>
                            <div className="col-sm-12 col-md-4 col-lg-4 mr-b20">
                                <TextInputPhone
                                    required={false}
                                    source={`otherphone`}
                                    label={`Other Phone `}
                                    // isCountryCode={`applicant.mobile.${0}.country_code`}
                                    labelClass={"lable-title"}
                                    additionalInputClass={"cus-control"}
                                    widthClass={"w-100"}
                                    requiredClass={{
                                        fontSize: "70%",
                                    }}
                                // onChangeCallback={() => {
                                //   props?.handleAPIDebounced();
                                // }}
                                />
                            </div>

                            <div className="col-sm-12 col-md-4 col-lg-4 mr-b20">
                                <InputSelectField
                                    label={`Role`}
                                    source={`usertype`}
                                    options={
                                        getrole?.map((item, index) => (

                                            {
                                                value: item.RoleName,
                                                label: item?.RoleName,
                                            }
                                        ))
                                    }
                                    labelAsteriskClass={"color-red"}
                                    labelClass={"lable-title"}
                                    requiredClass={{
                                        fontSize: "70%",
                                    }}
                                    stopAutoFocus={true}
                                />
                            </div>
                        </div>
                        {/* <JSONTree data={associationtype?.value}/> */}
                        <div className="row">
                            {!isAdmin && methods.watch('usertype') != ""  && <div className="col-sm-12 col-md-4 col-lg-4 mr-b20">
                                <InputSelectField
                                    label={`Association Type`}
                                    source={`associationType`}
                                    options={
                                        associationTypes?.map((item, index) => ({
                                            label: item?.AssociationType,
                                            value: item?.AssociationType,
                                        }))
                                    }
                                    labelAsteriskClass={"color-red"}
                                    labelClass={"lable-title"}
                                    requiredClass={{
                                        fontSize: "70%",
                                    }}
                                    onChangeCallback={(event) => {
                                        methods.setValue("agentlist", []);
                                        methods.setValue("officeList", []);
                                        methods.setValue("hierarchyList", []);
                                      }}
                                />
                            </div>}
                            {!isAdmin && associationtype && associationtype?.value === "Property/Office" && <div className="col-sm-12 col-md-4 col-lg-4 mr-b20">
                                <InputSelectField
                                    label={`Select From List`}
                                    source={`officeList`}
                                    options={
                                        getassociation?.map((item, index) => ({
                                            label: item?.OfficeName || item.PropertyName,
                                            value: item?.ID || item.ID,
                                        }))
                                    }
                                    labelAsteriskClass={"color-red"}
                                    labelClass={"lable-title"}
                                    requiredClass={{
                                        fontSize: "70%",
                                    }}
                                    onChangeCallback={(event) => {

                                        methods.setValue("agentlist", []);
                                      
                                      }}
                                    // required={false}
                                    multiple={true}
                                />
                            </div>}
                            {associationtype && associationtype?.value === "Hierarchy" && <div className="col-sm-12 col-md-4 col-lg-4 mr-b20">
                                <InputSelectField
                                    label={`Hierarchy List`}
                                    source={`hierarchyList`}
                                    options={
                                        getHierarchyLevelDetails?.map((item, index) => ({
                                            label: item?.HierarchyName,
                                            value: item?.id,
                                        }))
                                    }
                                    labelAsteriskClass={"color-red"}
                                    labelClass={"lable-title"}
                                    requiredClass={{
                                        fontSize: "70%",
                                    }}
                                    // required={false}
                                    multiple={true}
                                />
                            </div>}
                            {/* <div className="col-sm-12 col-md-4 col-lg-4 mr-b20">
                                <InputSelectField
                                    label={`Select from List`}
                                    source={`application_profile`}
                                    options={""
                                        //     getApplicationProfile?.data?.map((item) => ({
                                        //     value: item?.ID,
                                        //     label: item?.ProfileName,
                                        //   }))
                                    }
                                    labelAsteriskClass={"color-red"}
                                    labelClass={"lable-title"}
                                    requiredClass={{
                                        fontSize: "70%",
                                    }}
                                />
                            </div> */}
                            {/* {!isAdmin && associationtype?.value === "Hierarchy" && <div className="col-sm-12 col-md-4 col-lg-4 mr-b20">
                                <InputSelectField
                                    label={`Agent List`}
                                    source={`agentlist`}
                                    options={getagent?.map((item, index) => ({
                                        value: item?.ID,
                                        label: item?.Name,
                                      }))}
                                    multiple={true}
                                    labelAsteriskClass={"color-red"}
                                    labelClass={"lable-title"}
                                    requiredClass={{
                                        fontSize: "70%",
                                    }}
                                    required={ false}
                                />
                            </div>} */}
                           
                            {!isAdmin && <div className="col-sm-12 col-md-4 col-lg-4 mr-b20">
                                <InputSelectField
                                    label={`Agent List`}
                                    source={`agentlist`}
                                    options={agentdata?.map((item, index) => ({
                                        value: item?.id,
                                        label: item?.FullName,
                                      }))}
                                    multiple={true}
                                    labelAsteriskClass={"color-red"}
                                    labelClass={"lable-title"}
                                    requiredClass={{
                                        fontSize: "70%",
                                    }}
                                    required={ false}
                                />
                            </div>}
                            {!isAdmin && !isManager && methods.watch('usertype') != "" && <div className="col-sm-12 col-md-4 col-lg-4 mr-b20">
                                <InputSelectField
                                    label={`Reporting Manager`}
                                    source={`managerid`}
                                    options={
                                        getmanager?.map((item, index) => (

                                            {
                                                value: item?.ID,
                                                label: item?.Name,
                                            }

                                        ))


                                    }

                                    labelAsteriskClass={"color-red"}
                                    labelClass={"lable-title"}
                                    requiredClass={{
                                        fontSize: "70%",
                                    }}
                                // required={false}
                                />
                            </div>}
                        </div>
                        <div className="row">
                            <div className="col-sm-12 col-md-4 col-lg-4 mr-b20">
                                <ToggleButtons
                                    toggleButton={toggleButton}
                                    label="Multi Factor Authentication"
                                    labelClass={"lable-title"}
                                    source={`twofactorenabled`}
                                    onClickHandler={(e) => {
                                        // if (!state?.isAdding) {
                                        if (methods?.watch(`twofactorenabled`) != e?.target?.value) {
                                            setOpen(true);
                                        }
                                        // }
                                    }}
                                />
                            </div>
                        </div>
                
                        <Box
                           
                            display={"flex"}
                            justifyContent={"end"}
                            alignItems={"center"}
                            className="mt-4"
                            minHeight="10vh"
                        >
                            <Box>
                                <Button
                                    sx={{ mx: 1 }}
                                    onClick={() => {
                                        methods?.reset();
                                        navigate(-1);
                                    }}
                                    variant="outlined"
                                    color="secondary"
                                    type="button"
                                >
                                    Cancel
                                </Button>
                                <Button
                                    startIcon={!isLoading && <CheckIcon />}
                                    sx={{ mx: 1 }}
                                    variant="contained"
                                    type="submit"
                                    disabled={isLoading}
                                >
                                    {isLoading ? (
                                        <CircularProgress size="20px" sx={{ color: "white" }} /> // Show loader when isLoading is true
                                    ) : (
                                        <span>Create user</span> // Show text when isLoading is false
                                    )}
                                </Button>
                            </Box>
                        </Box>
                    </form>
                </FormProvider>
            </div>
            <MFAPopup
                open={open}
                setOpen={setOpen}
                setValue={methods?.setValue}
                status={methods?.getValues("twofactorenabled")}
            />
        </Fragment>
    );
};

export default AddOrUpdateUser;



