import React, { useEffect } from 'react'
import { Box, Button, IconButton, InputAdornment } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { TextInput, required, useUpdate, useNotify, useRefresh, NumberInput, useGetOne, useGetList, useGetIdentity, usePermissions } from 'react-admin';
import { useForm, FormProvider, get } from 'react-hook-form';
import EditIcon from '@mui/icons-material/Edit';
import { useParams } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import InputCurrencyField from '../DocumentAnalysis/components/inputComponents/InputCurrencyFiled';
import * as Yup from "yup";
import { yupResolver } from '@hookform/resolvers/yup';
import InputSelectField from '../common/InputComponents/InputSelectField';
import { typesofUsers } from '../../config';
import { checkModuleAccess } from '../../Functions/permissions';
import { useSelector } from 'react-redux';

export default function AddressRentEditForProperty(props) {
    const applicationNumber = props.record.id;
    const notify = useNotify();
    const refresh = useRefresh();
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [preSelectedFloor, setPreSelectedFloor] = React.useState();
    const [preSelectedUnit, setPreSelectedUnit] = React.useState();
    const [propertyId, setPropertyId] = React.useState();
    const [subscriberId, setSubscriberId] = React.useState();

    const { data: applicantData, isLoading } = useGetOne("getapplicationinboxdetails", {
        id: applicationNumber,
    }, { enabled: open });
    useEffect(() => {
        if (isLoading) return;
        if (!applicantData) return;

        const applicationData = applicantData?.data[0];
        if (applicationData) {
            setPreSelectedFloor(applicationData.FloorID);
            setPreSelectedUnit(applicationData.UnitID);
            setPropertyId(applicationData.PropertyID);
            setSubscriberId(applicationData.SubscriberID);
        }
    }, [applicantData])
    const validationSchema = Yup.lazy((value) =>
        Yup.object().shape({
            rent: Yup.string()
                .matches(/^(\d*[0-9])|^$/, "Only Numbers Allowed")
        })
    );

    // filter a data
    const form = useForm<any>({
        defaultValues: {
            floor: { label: "", value: "" },


            unit: { label: "", value: "" },

            rent: props.record.AppliedForRent,

        },

        resolver: yupResolver(validationSchema),
    });


    const methods = form;

    const { data: unitData, isError, isLoading: isLoadingUnitData } = useGetList(
        "getunitlist",
        {
            filter: {
                PropertyID: preSelectedFloor,
                SubscriberID: subscriberId,
            },
        },
        { enabled: !!preSelectedFloor }
    );


    const { data: floorData, isError: isFloorApiError, isLoading: isLoadingFloorData } = useGetList(
        "getfloorlist",
        {
            filter: {
                PropertyID: propertyId,
                SubscriberID: subscriberId,
            },
        },
        { enabled: open }
    );



    // form validation schema 



    React.useEffect(() => {
        if (props?.record && floorData && unitData) {
            const selectedFloor = floorData
                ?.filter((item) => ((item.ID == preSelectedFloor)))
                ?.map((item) => ({
                    label: item.FloorCode,
                    value: item.ID,
                }));  // Get the first matching element

            const selectedUnit = unitData
                ?.filter((item) => item.ID == preSelectedUnit)
                .map((item) => ({
                    label: item.UnitName,
                    value: item.ID,
                }))[0];  // Get the first matching element

            // Set the values only when they exist
            if (selectedFloor) methods.setValue('floor', selectedFloor);
            if (selectedUnit) methods.setValue('unit', selectedUnit);
        }
    }, [props?.record, floorData, unitData, preSelectedFloor, preSelectedUnit]);


    const [update, { data }] = useUpdate();

    // form on onsubmit handler
    const onSubmit = (value) => {

        const sendData = {

            rent: value?.rent,
            floor_id: parseInt(value?.floor?.value),
            unit_id: parseInt(value?.unit?.value),
        }

        update('editapplicationdetail', { id: props.record.id, data: sendData }, {
            onSuccess: (d) => {
                notify(`Data Updated`, { type: 'success', anchorOrigin: { vertical: 'top', horizontal: 'right' } })
                refresh();
                handleClose();
            }, onError: (e: any) => notify(`Operation fail:${e.message}`, { type: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' } })
        })
    }
    const { data: identity } = useGetIdentity();
    const { permissions } = usePermissions();
    const userRole =
        useSelector((state: any) => state?.users?.role) || identity?.userrole;
    return (
        <>
            <EditIcon onClick={handleOpen} color='primary' fontSize='small' style={{ fontSize: props?.fontSize ? props?.fontSize : "inherits" }} />

            <Dialog
                fullWidth
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle>
                    Edit
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: "absolute",
                        right: 6,
                        top: 1,

                        color: (theme) => theme.palette.grey[900],
                    }}
                >
                    {/* <span style={{ fontSize: "12px" }}>Dismiss</span> */}
                    <CloseIcon style={{ fontSize: "12px" }} />
                </IconButton>
                <DialogContent>
                    <FormProvider {...form}>
                        {isLoading && isLoadingFloorData &&<div>Loading...</div>}
                        {(!isLoading && !isLoadingFloorData) && <form onSubmit={form.handleSubmit(onSubmit)}>
                            <fieldset disabled={userRole == typesofUsers.docuverusAdmin && !checkModuleAccess(permissions, "Dashboard", "Edit Application")}>
                                <div className="row">
                                    <div className="col-12 mr-b10">
                                        <InputSelectField
                                            label="Floor"
                                            source={`floor`}
                                            options={
                                                floorData?.map((item) => ({
                                                    label: item.FloorCode,
                                                    value: item.ID,
                                                }))
                                            }
                                            isClearable={false}
                                            labelAsteriskClass={"color-red"}
                                            labelClass={"lable-title"}
                                            requiredClass={{
                                                fontSize: "70%",
                                            }}
                                            onChangeCallback={(value) => {
                                                setPreSelectedFloor(value.value);
                                                methods.setValue('unit', null);
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12 mr-b10">
                                        <InputSelectField
                                            label="Unit"
                                            source={`unit`}
                                            options={
                                                unitData?.map((item) => ({
                                                    label: item.UnitName,
                                                    value: item.ID,
                                                }))

                                            }
                                            labelAsteriskClass={"color-red"}
                                            labelClass={"lable-title"}
                                            requiredClass={{
                                                fontSize: "70%",
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12 mr-b10">
                                        <InputCurrencyField label="Rent" source={`rent`} placeholder="Rent" required={true} labelClass={"lable-title"} requiredClass={{ fontSize: "70%" }} />
                                    </div>
                                </div>

                                <Box display={'flex'} justifyContent={'end'}>
                                    <Button sx={{ mx: 1 }} onClick={() => { handleClose(); }} variant='outlined' color='secondary' type='button' >
                                        Cancel
                                    </Button>

                                    <Button startIcon={<EditIcon />} sx={{ mx: 1 }} variant='contained' type='submit'>
                                        Update
                                    </Button>
                                </Box>
                            </fieldset>
                        </form>}
                    </FormProvider>
                </DialogContent>

            </Dialog>

        </>
    )
}
